.searchItem {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 2px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 3px 10px 0px 0px;
}

/* .searchItem:nth-last-child(2) {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 2px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 1.5px 0px 1.5px 0px;
} */

.searchIncomplete {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 2px;
  border: 1px solid rgb(232, 72, 72);
  border-radius: 5px;
  margin: 1.5px 8px 1.5px 0px;
}

.searchvalue {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchItem .key,
.searchItem .operator,
.searchItem .valueContainer {
  background: #e7e7ea;
  padding: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 1px;
  white-space: nowrap;
}

.searchIncomplete .key,
.searchIncomplete .operator,
.searchIncomplete .valueContainer {
  background: #e7e7ea;
  padding: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 1px;
  white-space: nowrap;
}
.suggestionBox {
  margin-top: 7px;
}

.searchInputBox {
  height: 100%;
  width: 100%;
}
.searchInputBox input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 14pt;
}
.searchResults {
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
  margin: 0px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 5px;
}
.searchInputBox input:focus {
  outline: none;
}

ul.searchResults.hidden {
  display: none;
}

.show {
  display: block;
}

.searchResults li {
  list-style: none;
  padding: 0px 10px;
  height: auto;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.searchResults li:hover {
  background-color: #ebebeb;
  color: rgb(0, 0, 0);
}

.searchResults li:first-child {
  border-radius: 5px 5px 0 0;
}

.searchResults li:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.doneBtn {
  width: 100%;
}

.more-container {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.showMore {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
