.auth-container {
  height: 100vh;
  background-image: url("../images/00_Background.png");
}

.logo {
  height: 64px;
  background-image: url("../images/visteon_logo.png");
  background-repeat: no-repeat;
  background-position: 27px 15px;
  background-color: white;
  display: none;
}

.fragmentContainer {
  padding: 20px;  
  background: #ececec;  
}

.fileUploadPage.box-1 {
  max-width: 240px;
}

.outline-1 {
  outline: 1px solid black;
}

.wizard-item {
  margin-right: 2%;
}
.download-loader {
  margin-right: 2%;
  margin-top: 0.25%;
}
.menu-item-selected {
  border-left: 2px solid;
  position: absolute;
  left: 0;
  height: 48px;
  width: 2px;
}
.dotted-text {
  padding: 0;
  margin: 0;
  font-size: small;
  font-weight: 500;
  color: #9e9e9e;
  text-transform:capitalize;
}
.card-anchor {
  text-decoration: none;
}
.dotted-description {
  font-size: smaller;
  font-weight: 300;
  padding: 0 5%;
}
.dotted-icon {
  padding-top: 5%;
}
.tile-cards {
  min-height: 120px;
}
.tile-number {
  padding-top: 4%;
}
.route-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15% 0;
}

.logo-container {
  height: 239px;
  width: 100%;
  /* padding-top: 58px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoicon {
  height: 168px;
  width: 168px;
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  border-radius: 100%;
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 50%);
  
}

.logoicon.orange {
  background-image: url("../images/logo/Orange.svg");
}

.logoicon.indigo {
  background-image: url("../images/logo/Indigo.svg");
}

.logoicon.teal {
  background-image: url("../images/logo/Teal.svg");
}

.logoicon.purple {
  background-image: url("../images/logo/purple.svg");
}

.tabs-container {
  /* padding: 2% 0; */
}

.internal-breadcrumb {
  padding-top: 1%;
}
